import "jquery";
import "bootstrap";
import iziToast from "iziToast";

(function (document, window, $) {
  $(document).ready(function ($) {
    $.ajaxSetup({
      headers: {
        "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
      },
    });

    iziToast.settings({
      theme: "dark",
      color: "#117E9B",
      timeout: 10000,
      resetOnHover: true,
      position: "topCenter",
      transitionIn: "bounceInDown",
      transitionOut: "bounceOutDown",
      progressBarColor: "#ffffff",
      iconColor: "#e5f4f0",
      layout: 2,
      closeOnEscape: true,
      closeOnClick: true,
    });

    if (typeof toast_message == "string") {
      iziToast.show({ message: toast_message, timeout: 5000 });
    }

    if (typeof toast_error_message == "string") {
      iziToast.error({
        class: "iziToast iziToastRed",
        message: toast_error_message,
        timeout: 6000,
      });
    }

    $('[data-toggle="tooltip"]').tooltip({
      container: "body",
      placement: "bottom",
    });

    $('[data-toggle="tooltip"]').on("focus", function () {
      $(this).blur();
    });

    // Enable link to Bootstrap Tabs
    var url = document.location.toString();
    if (url.match("#")) {
      $('ul.nav-tabs a[href="#' + url.split("#")[1] + '"]').tab("show");
    }
    // Change hash for page-reload
    $("ul.nav-tabs a").on("shown.bs.tab", function (e) {
      window.location.hash = e.target.hash;
    });

    // show notes
    $(".js--open-all").on("click", function () {
      $(".js--hide-at-start").slideToggle();
      $(this).toggleClass("opened");
    });
  });
})(document, window, jquery, undefined);
